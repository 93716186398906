import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const SuccessMessageStyles = styled.section`
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  animation: slide-in-bottom 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  border-radius: 5px;
  .sm {
    background-color: white;
    width: 100%;
    padding: 2rem;
    strong {
      font-size: 1.45rem;
    }
    p {
      margin-top: 0.5rem;
    }
    button {
      width: 100%;
      cursor: pointer;
    }
  }
  @media ${media.sm} {
    width: 50%;
  }
  @media ${media.md} {
    width: 50%;
    bottom: calc(0% + 2.55%);
    right: calc(0% + 2.5%);
  }
  @media ${media.xl} {
    width: 30%;
    bottom: calc(0% + 5%);
    right: calc(0% + 5%);
  }
`
const SuccessMessage = ({ success, setSuccess }) =>
  success ? (
    <SuccessMessageStyles>
      <div className="sm">
        <strong>Thank You</strong>
        <p>
          We've received your message and will get back to you within 24 hours.
        </p>
        <button type="button" onClick={() => setSuccess(false)}>
          <span>Close</span>
        </button>
      </div>
    </SuccessMessageStyles>
  ) : (
    ''
  )

export default SuccessMessage
