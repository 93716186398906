import React from 'react'
import MainNav from '../components/functional/mainNav'
import Layout from '../components/layout'
import ContactBody from '../components/contact/contactBody'

const metaData = {
  title: 'Contact Us | D E W Y N T E R S',
}

const ContactUs = () => (
  <Layout title={metaData.title}>
    <MainNav alt />
    <ContactBody />
  </Layout>
)

export default ContactUs
